import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const contentVideoInitialValues = {
  status: 'uploading',
};

export const ContentVideoCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm initialValues={contentVideoInitialValues} redirect="edit">
      <TextInput source="title" />
    </SimpleForm>
  </Create>
);
