import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { stringify } from 'query-string';
import React from 'react';
import { useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import { stopPropagation } from '../../ui/utils';
import advideos from '../videos/advideos';

const useStyles = makeStyles({
  icon: { paddingRight: '0.5em' },
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
});

const LinkToRelatedAdVideos = ({ record }: any) => {
  const translate = useTranslate();
  const classes = useStyles({});
  return record ? (
    <Button
      size="small"
      color="primary"
      component={Link}
      to={{
        pathname: '/av',
        search: stringify({
          page: 1,
          perPage: 10,
          sort: 'id',
          order: 'DESC',
          filter: JSON.stringify({
            customer: { id: record.id },
          }),
        }),
      }}
      className={classes.link}
      onClick={stopPropagation}
    >
      <advideos.icon className={classes.icon} />
      {translate('resources.av.name', {
        smart_count: 2,
      })}
    </Button>
  ) : null;
};

export default LinkToRelatedAdVideos;
