import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';
import { AdVideoList } from './List';
import { AdVideoEdit } from './EditForm';
import { AdVideoCreate } from './CreateForm';

export default {
  list: AdVideoList,
  create: AdVideoCreate,
  edit: AdVideoEdit,
  icon: FeaturedVideoIcon,
};
