import React, { useState } from 'react';
import { FunctionField, Labeled } from 'react-admin';

export const TruncatedField = (props: any) => {
  const truncatedChars = 8;
  const [isExpanded, setExpanded] = useState(false);
  let value = props.record[props.source];
  if (value && !isExpanded && value.length > truncatedChars) {
    value = value.substr(0, truncatedChars) + '...';
  }
  return (
    <FunctionField
      {...props}
      render={() => value}
      onClick={() => setExpanded(!isExpanded)}
    />
  );
};
