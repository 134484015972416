import get from 'lodash/get';
import React from 'react';
import LinkField from '../../ui/fields/LinkField';
import { getCustomerName } from './utils';

const CustomerRefField = ({ record, source }: any) => {
  const customer = get(record, source);

  return customer ? (
    <LinkField to={`/customers/${customer.id}`}>
      {getCustomerName(customer)}
    </LinkField>
  ) : null;
};

export default CustomerRefField;
