import axios from 'axios';
import { config } from '../config';

axios.defaults.baseURL = config.apiEndpoint;

axios.interceptors.request.use(axiosConfig => {
  if (!axiosConfig.headers.Authorization) {
    const token = localStorage.getItem('access_token');
    axiosConfig.headers.Authorization = `Bearer ${token}`;
  }
  return axiosConfig;
});

export default axios;
