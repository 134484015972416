import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import {
  ArrayInput,
  Create,
  DateField,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { DateTimeInput } from '../../ui/fields';

const statusChoices = [
  { id: 'active', name: 'Aktiv' },
  { id: 'inactive', name: 'Inaktiv' },
];

const AdVideoInput = (props: any) => {
  const choice = props.choices.find((c: any) => c.id === props.input.value);
  return (
    <>
      <SelectInput {...props} optionText="title" openValue="id" />

      {choice ? (
        <>
          <div>
            <Typography variant={'body2'}>
              <b>Vimeo-ID:</b>{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://vimeo.com/${choice.videoId}`}
              >
                {choice.videoId}
              </a>
            </Typography>
            <Typography variant={'body2'}>
              <b>Erstellungsdatum:</b>{' '}
              <DateField
                record={choice}
                source="createdAt"
                locales="de-DE"
                showTime
              />
            </Typography>
            <Typography variant={'body2'}>
              <b>Gesamtbudget:</b> {choice.finishCount} /{' '}
              {choice.viewBudget || 'kein Limit'}
            </Typography>
          </div>
        </>
      ) : null}
    </>
  );
};
const AdVideosSelector = (props: any) => {
  return (
    <ArrayInput {...props} source="adVideoAssignments">
      <SimpleFormIterator>
        <ReferenceInput
          label="Video auswählen"
          reference="av"
          source="adVideoId"
          filter={{ status: 'active' }}
          sort={{ field: 'createdAt', order: 'DESC' }}
        >
          <AdVideoInput />
        </ReferenceInput>
        <TextInput source="viewBudgetDaily" resettable />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export const CampaignCreate = (props: any) => {
  const initialValues = useMemo(() => {
    const dateFrom = new Date();
    const dateTo = new Date();
    dateFrom.setHours(0, 0, 0);
    dateTo.setHours(23, 59, 59);

    return {
      dateFrom,
      dateTo,
      status: 'inactive',
    };
  }, []);

  return (
    <Create {...props}>
      <SimpleForm redirect="list" initialValues={initialValues}>
        <SelectInput source="status" choices={statusChoices} />
        <DateTimeInput source="dateFrom" />
        <DateTimeInput source="dateTo" />
        <>
          <br />
          <Typography variant="h6">Videozuordnung</Typography>
        </>
        <ReferenceInput source="contentVideoId" reference="cv">
          <SelectInput optionText="title" />
        </ReferenceInput>
        <AdVideosSelector />
      </SimpleForm>
    </Create>
  );
};

export const CampaignEdit = (props: any) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <SelectInput source="status" choices={statusChoices} />
      <DateTimeInput source="dateFrom" />
      <DateTimeInput source="dateTo" />
      <>
        <br />
        <Typography variant="h6">Videozuordnung</Typography>
      </>
      <ReferenceInput
        source="contentVideoId"
        reference="cv"
        filter={{ status: 'active' }}
        sort={{ field: 'createdAt', order: 'DESC' }}
      >
        <SelectInput optionText="title" />
      </ReferenceInput>
      <AdVideosSelector />
    </SimpleForm>
  </Edit>
);
