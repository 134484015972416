import { Button, Card, CardContent, Typography } from '@material-ui/core';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import Vimeo from '@u-wave/react-vimeo';
import React, { useEffect, useState } from 'react';
import { useField } from 'react-final-form';

export const VideoViewer = ({
  videoId: initialVideoId,
}: {
  videoId: string;
}) => {
  const videoIdField = useField('videoId');
  const [videoId, setVideoId] = useState(initialVideoId);
  const fieldVideoId = videoIdField?.input?.value?.trim();
  const [hasError, setError] = useState(false);

  useEffect(() => {
    if (hasError) {
      setError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId]);

  return (
    <Card>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Video-Vorschau
        </Typography>

        {fieldVideoId !== videoId ? (
          <Button
            startIcon={<RefreshIcon />}
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => setVideoId(fieldVideoId)}
          >
            Update
          </Button>
        ) : null}

        {hasError ? (
          <Typography variant="body1" component="p">
            Das Video konnte nicht geladen werden...
          </Typography>
        ) : (
          <Vimeo video={videoId} onError={() => setError(true)} />
        )}
      </CardContent>
    </Card>
  );
};
