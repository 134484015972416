import PeopleIcon from '@material-ui/icons/People';
import { CustomerCreate, CustomerEdit } from './CustomersForms';
import { CustomerList } from './CustomerList';

export default {
  list: CustomerList,
  create: CustomerCreate,
  edit: CustomerEdit,
  icon: PeopleIcon,
};
