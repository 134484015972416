import React from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  List,
  TextField,
  TextInput,
  Pagination,
} from 'react-admin';
import LinkToRelatedAdVideos from './LinkToRelatedAdVideos';

const CustomerFilter = (props: any) => (
  <Filter {...props}>
    <TextInput source="company" />
    <TextInput source="firstName" />
    <TextInput source="lastName" />
  </Filter>
);

export const CustomerList = (props: any) => (
  <List
    {...props}
    sort={{ field: 'id', order: 'DESC' }}
    filters={<CustomerFilter />}
    pagination={<Pagination />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="company" />
      <DateField source="createdAt" showTime locales="de-DE" />
      <LinkToRelatedAdVideos />
    </Datagrid>
  </List>
);
