/* eslint-disable react/prop-types */
import React from 'react';
import { FieldTitle } from 'react-admin';
import { DateTimeInput as OrigDateTimeInput } from 'react-admin-date-inputs';

const defaultFormat = 'dd.MM.yyyy HH:mm';

export const DateTimeInput = (props: any) => {
  const { label, source, resource, isRequired, options } = props;
  return (
    <OrigDateTimeInput
      {...props}
      label={
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      }
      options={{
        format: defaultFormat,
        ampm: false,
        showTodayButton: true,
        ...options,
      }}
      alwaysOn
    />
  );
};
