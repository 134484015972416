import React from 'react';
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { getCustomerName } from '../../customers/utils';
import { requiredValidator } from '../validators';

const advideoInitialValue = {
  status: 'uploading',
};

export const AdVideoCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm initialValues={advideoInitialValue} redirect="edit">
      <TextInput source="title" validate={requiredValidator} />
      <TextInput source="targetUrl" />

      <ReferenceInput source="customer.id" reference="customers">
        <SelectInput optionText={getCustomerName} resettable />
      </ReferenceInput>

      <TextInput source="viewBudget" />
    </SimpleForm>
  </Create>
);
