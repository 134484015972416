import { Box, Card, CardContent, Grid } from '@material-ui/core';
import React from 'react';
import {
  Edit,
  FormWithRedirect,
  SelectInput,
  TextInput,
  Toolbar,
} from 'react-admin';
import { EditActions } from '../EditActions';
import { statusChoices } from '../statusChoices';
import { UploadForm } from '../UploadForm';
import { requiredValidator, videoIdValidator } from '../validators';
import { VideoViewer } from '../VideoViewer';

export const EditForm = (props: any) => (
  <FormWithRedirect
    {...props}
    render={(formProps: any) => (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <form>
                <CardContent>
                  <TextInput
                    source="videoId"
                    label="Vimeo-ID"
                    validate={videoIdValidator}
                    fullWidth
                  />
                  <Box mt="1em" />
                  <SelectInput
                    source="status"
                    choices={statusChoices}
                    validate={requiredValidator}
                    fullWidth
                  />
                  <TextInput
                    source="title"
                    label="Titel"
                    validate={requiredValidator}
                    fullWidth
                  />
                </CardContent>
                <Toolbar
                  record={formProps.record}
                  basePath={formProps.basePath}
                  undoable
                  invalid={formProps.invalid}
                  handleSubmit={formProps.handleSubmit}
                  saving={formProps.saving}
                  resource="cv"
                />
              </form>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <UploadForm {...formProps} />
          </Grid>
          {formProps.record?.videoId && (
            <Grid item xs={12} md={12}>
              <VideoViewer videoId={formProps.record?.videoId} />
            </Grid>
          )}
        </Grid>
      </>
    )}
  />
);

export const ContentVideoEdit = (props: any) => (
  <Edit {...props} undoable={false} component="div" actions={<EditActions />}>
    <EditForm />
  </Edit>
);
