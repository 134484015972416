import React from 'react';
import { Create, Edit, SimpleForm, TextInput } from 'react-admin';

export const CustomerCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="company" />
    </SimpleForm>
  </Create>
);

export const CustomerEdit = (props: any) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="company" />
    </SimpleForm>
  </Edit>
);
