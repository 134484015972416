import React from 'react';
import { Datagrid, DateField, List, TextField, Pagination } from 'react-admin';

export const CampaignList = (props: any) => (
  <List
    {...props}
    sort={{ field: 'dateFrom', order: 'DESC' }}
    pagination={<Pagination />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="status" />
      <DateField source="dateFrom" locales="de-DE" showTime />
      <DateField source="dateTo" locales="de-DE" showTime />
    </Datagrid>
  </List>
);
