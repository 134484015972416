import React, { Children, cloneElement } from 'react';
import { Link } from 'react-router-dom';
import { stopPropagation } from '../utils';

export default ({ to, children, ...rest }: any) => {
  return (
    <Link to={to} onClick={stopPropagation}>
      {/* {Children.map(children, child => cloneElement(child, rest))} */}
      {Array.isArray(children)
        ? cloneElement(Children.only(children), { ...rest })
        : children}
    </Link>
  );
};
