import { config } from '../config';

export default {
  // called when the user attempts to log in
  async login({ username, password }: { username: string; password: string }) {
    const data = await (
      await fetch(`${config.apiEndpoint}/auth/login`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password,
          email: username,
        }),
      })
    ).json();

    if (!data || !data.access_token) {
      throw new Error('Login failed');
    }
    localStorage.setItem('email', username);
    localStorage.setItem('access_token', data.access_token);
  },

  // called when the user clicks on the logout button
  async logout() {
    localStorage.removeItem('email');
    localStorage.removeItem('access_token');
  },

  // called when the API returns an error
  async checkError({ status }: { status: number }) {
    if (status === 401 || status === 403) {
      localStorage.removeItem('email');
      localStorage.removeItem('access_token');
      throw new Error('Unauthorized');
    }
  },

  // called when the user navigates to a new location, to check for authentication
  async checkAuth() {
    if (!localStorage.getItem('access_token')) {
      throw new Error('Login timeout');
    }
  },

  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions() {
    return Promise.resolve();
  },
};
