import React from 'react';
import { Admin, Resource } from 'react-admin';
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import i18nProvider from './providers/i18nProvider';
import campaigns from './resources/campaigns';
import customers from './resources/customers';
import Dashboard from './resources/Dashboard';
import advideos from './resources/videos/advideos';
import theme from './ui/theme';
import contentvideos from './resources/videos/contentvideos';
import views from './resources/views';

const App = () => (
  <Admin
    theme={theme}
    authProvider={authProvider}
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
    dashboard={Dashboard}
  >
    <Resource name="customers" {...customers} />
    <Resource name="campaigns" {...campaigns} />
    <Resource name="av" {...advideos} />
    <Resource name="cv" {...contentvideos} />
    <Resource name="views" {...views} />
  </Admin>
);
export default App;
