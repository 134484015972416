import polyglotI18nProvider from 'ra-i18n-polyglot';
import germanMessages from 'ra-language-german';

import domainMessages from '../i18n/messages';

const messages = {
  ...germanMessages,
  ...domainMessages,
};

const i18nProvider = polyglotI18nProvider(() => messages, 'de');

export default i18nProvider;
