import { fetchUtils } from 'react-admin';
import crudProvider from './nestjs-crud/nestJsCrudProvider';
import { config } from '../config';

const httpClient = (url: string, opt: any = {}) => {
  const options = { ...opt };
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('access_token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};
export default crudProvider(config.apiEndpoint, httpClient);
