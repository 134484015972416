import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { MouseEventHandler, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import * as tus from 'tus-js-client';
import axios from '../../services/axios';

const useStyles = makeStyles({
  container: {
    height: '100%',
  },
  dropzone: {
    minHeight: '10em',
    padding: '1em',
    marginBottom: '1em',
    border: '1px dotted grey',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

const DropArea = ({
  onDropAccepted,
}: {
  onDropAccepted: DropzoneOptions['onDropAccepted'];
}) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    accept: 'video/*',
    multiple: false,
  });
  const classes = useStyles({});

  const file = acceptedFiles[0];
  return (
    <section>
      <div {...getRootProps({ className: classes.dropzone })}>
        <input {...getInputProps()} />
        <Typography>
          Zieh die hochzuladende Videodatei in diesen Bereich oder klicke hier.
        </Typography>
      </div>
      {file ? (
        <aside>
          <Typography variant="caption">Ausgewählte Datei:</Typography>
          <ul>
            <Typography variant="caption">
              {file.name} - {Math.round((file.size / 1024 / 1024) * 100) / 100}
              MB
            </Typography>
          </ul>
        </aside>
      ) : null}
    </section>
  );
};

enum UploadState {
  NONE,
  ERROR,
  DROPPED,
  UPLOADING,
  SUCCEEDED,
}

export const UploadForm = (props: any) => {
  const id = props.record?.id;

  const [file, setFile] = useState<File>();
  const [uploadState, setUploadState] = useState<UploadState>(UploadState.NONE);
  const [progress, setProgress] = useState(0);

  const onDropAccepted = (files: File[]) => {
    console.log('DROP ACCEPTED', files);
    setFile(files[0]);
    setUploadState(UploadState.DROPPED);
  };

  const onClickUpload: MouseEventHandler<HTMLButtonElement> = async (event) => {
    if (!file) return;

    setUploadState(UploadState.UPLOADING);
    setProgress(0);

    // get upload url
    const {
      data: {
        data: { uploadLink },
      },
    } = await axios.post(`${props.resource}/${id}/upload`, {
      fileName: file.name,
      size: String(file.size),
    });

    if (!uploadLink) {
      setUploadState(UploadState.ERROR);
      return;
    }

    // start upload
    const upload = new tus.Upload(file, {
      endpoint: '',
      uploadUrl: uploadLink,
      headers: {
        Accept: 'application/vnd.vimeo.*+json;version=3.4',
      },
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      onChunkComplete(chunk) {
        console.log('chunk complete!', chunk);
      },
      onError(error) {
        console.error('upload error', error);
        setUploadState(UploadState.ERROR);
      },
      onProgress(bytesUploaded, bytesTotal) {
        const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        console.log(bytesUploaded, bytesTotal, `${percentage}%`);
        setProgress(Number(percentage));
      },
      onSuccess() {
        console.log('UPLOAD SUCCEEDED!');
        setUploadState(UploadState.SUCCEEDED);
      },
    });
    const previousUploads = await upload.findPreviousUploads();
    if (previousUploads.length > 0) {
      upload.resumeFromPreviousUpload(previousUploads[0]);
    }
    upload.start();
  };

  const classes = useStyles({});
  return (
    <Card className={classes.container} elevation={2}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Video-Upload
        </Typography>
        <Box padding={2}>
          <DropArea onDropAccepted={onDropAccepted} />
        </Box>
        <Box paddingX={2} paddingBottom={5}>
          <Button
            variant="contained"
            onClick={onClickUpload}
            disabled={!file || uploadState === UploadState.UPLOADING}
          >
            Upload
          </Button>
        </Box>
        {uploadState === UploadState.UPLOADING ? (
          <Box padding={2}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        ) : null}
        {uploadState === UploadState.SUCCEEDED ? (
          <Box padding={2}>
            <Typography>UPLOAD SUCCEEDED!</Typography>
          </Box>
        ) : null}
        {uploadState === UploadState.ERROR ? (
          <Box padding={2}>
            <Typography>Beim Upload ist ein Fehler aufgetreten!</Typography>
          </Box>
        ) : null}
      </CardContent>
    </Card>
  );
};
