import { Card, CardContent } from '@material-ui/core';
import { Videocam } from '@material-ui/icons';
import React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  ChipField,
  FunctionField,
} from 'react-admin';
import { IsDuplicateFilter, TypeFilter } from './filters';
import { TruncatedField } from './TruncatedField';

const FilterSidebar = () => (
  <Card>
    <CardContent>
      <TypeFilter />
      <IsDuplicateFilter />
    </CardContent>
  </Card>
);

export const ViewsList = (props: any) => (
  <List
    aside={<FilterSidebar />}
    {...props}
    sort={{ field: 'date', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <DateField source="date" locales="de-DE" showTime />
      <FunctionField
        label="Vimeo"
        render={(record: any) => (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://vimeo.com/${record.videoId}`}
          >
            <Videocam />
          </a>
        )}
      />
      <TextField source="videoType" />
      <NumberField source="campaignId" />
      <TextField source="ip" />
      <TruncatedField source="userAgent" />
      <TruncatedField source="userId" />
      <ChipField source="type" />
      <BooleanField source="isDuplicate" />
    </Datagrid>
  </List>
);
