const messages = {
  resources: {
    customers: {
      name: 'Kunde |||| Kunden',
      fields: {
        firstName: 'Vorname',
        lastName: 'Nachname',
        company: 'Firma',
      },
    },
    campaigns: {
      name: 'Kampagne |||| Kampagnen',
      fields: {
        dateFrom: 'Startzeit',
        dateTo: 'Endzeit',

        contentVideoId: 'Contentvideo',
        adVideos: 'Werbevideos',

        viewBudgetDaily: 'Tagesbudget (Ansichten)',
        adVideoAssignments: 'Werbevideo-Zuordnungen',
      },
    },
    av: {
      name: 'Werbevideo |||| Werbevideos',
      fields: {
        videoId: 'Vimeo-ID',
        title: 'Titel',
        customer: 'Kunde',
        'customer.id': 'Kunde',
        targetUrl: 'Zieladresse (URL)',
        viewBudget: 'Abspiel-Budget',
        finishCount: 'Abgespielt (Intern)',
        finishCountProvider: 'Abgespielt (Vimeo)',
        viewCount: 'Ansichten (Intern)',
        targetUrlClicks: 'Clicks (URL)',
      },
    },
    cv: {
      name: 'Contentvideo |||| Contentvideos',
      fields: {
        videoId: 'Vimeo-ID',
        title: 'Titel',
        finishCount: 'Abgespielt (Intern)',
        finishCountProvider: 'Abgespielt (Vimeo)',
        viewCount: 'Ansichten (Intern)',
      },
    },
    views: {
      name: 'View |||| Views',
      fields: {
        date: 'Zeit',
        campaignId: 'CP',
        isDuplicate: 'Duplikat',
        userId: 'UID',
        type: 'Typ',
        ip: 'IP',
        userAgent: 'User Agent',
      },
    },
  },
};

export default messages;
