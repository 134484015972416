import React from 'react';
import {
  Datagrid,
  Filter,
  List,
  NumberField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import CustomerRefField from '../../customers/CustomerRefField';
import { getCustomerName } from '../../customers/utils';
import { statusChoices } from '../statusChoices';

const AdVideoFilter = (props: any) => (
  <Filter {...props}>
    <TextInput source="id" />
    <TextInput source="videoId" />
    <SelectInput source="status" choices={statusChoices} />
    <TextInput source="title" />
    <ReferenceInput
      label="Kunde"
      source="customerId"
      reference="customers"
      sort={{ field: 'id', order: 'DESC' }}
      alwaysOn
    >
      <SelectInput
        source="id"
        optionText={getCustomerName}
        emptyText="-"
        resettable
      />
    </ReferenceInput>
  </Filter>
);

export const AdVideoList = (props: any) => (
  <List
    {...props}
    sort={{ field: 'id', order: 'DESC' }}
    filters={<AdVideoFilter />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />

      <TextField source="videoId" />
      <TextField source="status" />
      <TextField source="title" />

      <NumberField source="finishCountProvider" />
      <NumberField source="finishCount" />
      <TextField source="viewBudget" />
      <NumberField source="targetUrlClicks" />

      <CustomerRefField source="customer" />
    </Datagrid>
  </List>
);
