import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Typography } from '@material-ui/core';

import { useDataProvider, useVersion } from 'react-admin';
import { Link } from 'react-router-dom';

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },

  card: { marginBottom: '2em' },
};

export default () => {
  const dataProvider = useDataProvider();
  const version = useVersion();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>();

  const fetchCurrentCampaign = useCallback(async () => {
    const { data } = await dataProvider.getList('campaigns/current', {
      sort: undefined as any,
      filter: {},
      pagination: { page: 1, perPage: 1 },
    });
    console.log('got', data);
    setData(data);
    setLoading(false);
  }, [dataProvider]);

  useEffect(() => {
    fetchCurrentCampaign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version]);

  return (
    <div style={styles.flexColumn as CSSProperties}>
      <div style={styles.card}>
        <Card>
          <CardHeader title="One Minute For Nature Backend" />
          <CardContent>
            <Typography>
              Über die Navigationsleiste auf der linken Seite lassen sich die
              Videos der OMFN-Homepage verwalten.
            </Typography>
          </CardContent>
        </Card>
      </div>
      <div style={styles.card}>
        <Card>
          <CardHeader title={'Aktuelle Kampagne'} />
          <CardContent>
            {loading ? (
              <Typography>Wird geladen...</Typography>
            ) : (
              <Typography>
                {data === null ? (
                  'Keine Videos verfügbar'
                ) : (
                  <>
                    Derzeit aktiv ...
                    <br />
                    <b>Kampagne:</b>{' '}
                    <Link to={`/campaigns/${data.cpId}`}>{data.cpId}</Link>
                    <br />
                    <b>Content-Video:</b>{' '}
                    <Link to={`/cv/${data.cv?.id}`}>{data.cv?.id}</Link>
                    <br />
                    <b>Ad-Video:</b>{' '}
                    <Link to={`/av/${data.av?.id}`}>{data.av?.id}</Link>
                    <br />
                  </>
                )}
              </Typography>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
