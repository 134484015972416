import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import { ContentVideoEdit } from './EditForm';
import { ContentVideoCreate } from './CreateForm';
import { ContentVideoList } from './List';

export default {
  list: ContentVideoList,
  create: ContentVideoCreate,
  edit: ContentVideoEdit,
  icon: VideoLabelIcon,
};
