import React from 'react';
import { Datagrid, List, NumberField, TextField } from 'react-admin';

export const ContentVideoList = (props: any) => (
  <List {...props} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="videoId" />
      <TextField source="status" />
      <TextField source="title" />

      <NumberField source="finishCountProvider" />
      <NumberField source="finishCount" />
    </Datagrid>
  </List>
);
