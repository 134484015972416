import { Button } from '@material-ui/core';
import { Videocam } from '@material-ui/icons';
import React from 'react';
import { TopToolbar } from 'react-admin';

export const EditActions = ({ data }: any) => (
  <TopToolbar>
    <Button
      disabled={!data?.videoId}
      color="primary"
      startIcon={<Videocam />}
      component="a"
      href={`https://vimeo.com/${data?.videoId}`}
      target="_blank"
    >
      Vimeo
    </Button>
  </TopToolbar>
);
