import React from 'react';
import { FilterList, FilterListItem } from 'react-admin';

export const IsDuplicateFilter = () => (
  <FilterList label="Ist Duplikat">
    <FilterListItem label="Ja" value={{ isDuplicate: 1 }} />
    <FilterListItem label="Nein" value={{ isDuplicate: 0 }} />
  </FilterList>
);

export const TypeFilter = () => (
  <FilterList label="Typ">
    <FilterListItem label="View" value={{ type: 'view' }} />
    <FilterListItem label="Click" value={{ type: 'click' }} />
    <FilterListItem label="Finish" value={{ type: 'finish' }} />
  </FilterList>
);
