import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';

const omfnTheme = createMuiTheme({
  palette: {
    // primary: indigo,
    primary: { main: '#22b980' },
    secondary: { main: '#22b980' },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default omfnTheme;
